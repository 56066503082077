import { createRouter,createWebHashHistory } from "vue-router";

const quality = () => import("@/views/quality.vue")

const routes = [
    { path: "/", redirect: "/quality" },
    {
        path: "/quality",
        name: "quality",
        component: quality
    },
    {
        path: "/middle",
        name: "middle",
        component: quality
    }
]
const router = createRouter({
    history: createWebHashHistory(),
    routes: routes
})

router.afterEach((to) => {
    // console.log(document, 'document')
    let title = to.params.title ? to.params.title : ''
    if (to.meta && to.meta.title) {
      if (title !== '') {
        title += ' - ' + to.meta.title
      } else {
        title = to.meta.title
      }
    }
  
    document.title = title !== '' ? title + ' - 快搭云' : '快搭云'
  })
export default router
