import axios from 'axios'

import { ElMessage } from 'element-plus'



axios.interceptors.request.use(function (config) {
    // config.headers.Authorization = 'Token ' + token
    return config
}, function (error) {
    return Promise.reject(error)
})


axios.interceptors.response.use(function (response) {
    if (response.config.method === 'get' && response.config.cache) {
        // 缓存数据 并将当前时间存入 方便之后判断是否过期
        // console.log('存缓存数据')
        const data = {
            expire: getExpireTime(),
            data: response.data,
            status: 200
        }

        // 5M就到了Chrome的极限
        // 超过之后会抛出如下异常：
        // DOMException: Failed to execute 'setItem' on 'Storage': Setting the value of 'basket-http://file.com/ykq/wap/v3Templates/timeout/timeout/large.js' exceeded the quota
        try {
            sessionStorage.setItem(`${response.config.url}`, JSON.stringify(data))
        } catch (e) {
            if (e.name.toUpperCase().indexOf('QUOTA') >= 0) {
                console.debug('已经超出本地存储限定大小！不缓存数据')
            }
        }
        // cache[`${response.config.url}`] = data
    }
    return response
}, function (error) {
    if (axios.isCancel(error)) return Promise.resolve(error.Elmessage)
    console.debug(error)
    const resp = error.response
    if (resp.status === 400 || resp.status === 403) {

        ElMessage.error(resp.data.error )
    } else if (resp.status === 404) {
        ElMessage.error('访问数据不存在' )

    } else if (resp.status >= 500) {
        if (resp.status === 504) {
            ElMessage.error('连接服务器超时' )

        } else if (resp.status === 502) {
            ElMessage.error('服务器忙,请重试' )

        } else {
            ElMessage.error('服务器内部异常' )

        }
    }
    return Promise.reject(error)
})

// 获取当前时间
function getExpireTime() {
    return new Date().getTime()
}

export default axios